import React, { useContext, useEffect } from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import { AppContext } from "../context/AppContext"

import Boton from "../components/Boton"

const Conocenos = () => {
  const { setLogoColor } = useContext(AppContext)
  useEffect(() => {
    setLogoColor(false)
  }, [])
  return (
    <Layout title="Conócenos">
      <section>
        <div
          className={`min-h-screen text-white flex flex-col items-center justify-center relative`}
        >
          <div className="container mt-24 sm:mt-44">
            <div className=" flex flex-col sm:flex-row">
              <div className="text-center sm:w-1/2">
                <p
                  className="text-left font-light text-3xl sm:text-3xl md:text-5xl mb-4 sm:mb-0"
                  data-sal="slide-up"
                  data-sal-delay="300"
                  data-sal-duration="1000"
                >
                  Entre 2030 y 2050 la energía solar se volverá la fuente
                  predominante de generación de energía.
                </p>
              </div>
              <div className="flex flex-col font-regular justify-center items-center sm:w-1/2 sm:pl-8">
                <p className="text-base text-left mb-4 ">
                  Somos un grupo de jovenes emprendedores mexicanos envueltos en
                  la industria solar desde hace más de 15 años. Nuestra
                  motivación es aportar nuestros conocimientos en energías
                  limpias por el bien del planeta y todos los seres que lo
                  habitamos.
                </p>
                <p className="text-base text-left">
                  Cada sistema instalado ayuda a reducir la emisión de CO2. Cada
                  sistema instalado contribuye a un desarrollo sostenible.{" "}
                  <br />
                  En nuestros tiempos libres nos involucramos en proyectos de
                  regeneración de ecosistemas y habitat naturales locales.
                </p>

                <div className="mt-10 mb-10">
                  <Boton target="contact">Contáctanos</Boton>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-white pt-4 pb-6 w-full text-blue flex justify-center mt-auto">
            <div className="container">
              <div className="text-white">
                <div className="sm:grid sm:grid-cols-3">
                  <p className="text-sm sm:text-base text-blue font-medium mb-2 sm:mb-0">
                    © Ikaro 2022. Todos los derechos reservados.
                  </p>
                  <p className=" font-medium text-sm sm:text-base sm:text-center mb-2 sm:mb-0">
                    <span
                      className="cursor-pointer text-blue"
                      onClick={() => navigate("/aviso")}
                    >
                      Aviso de privacidad
                    </span>
                  </p>
                  <p className="text-blue2 font-medium text-sm sm:text-base sm:text-right">
                    Diseño y desarrollo web por{" "}
                    <a
                      href="https://www.trazovivo.com"
                      target="_blank"
                      className="font-display max-w-sm leading-tight"
                      rel="noreferrer"
                    >
                      <span className="link link-underline link-underline-black ">
                        Trazo Vivo
                      </span>
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Conocenos
